import React, { Fragment } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Speaker, Options } from "~/molecules/BaseArticleBody";
import { Seminar } from "~/constant/seminar";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { SeminarForm } from "~/organisms/SeminarForm";
import { SeminarDetailedForm } from "~/organisms/SeminarDetailedForm";
import dayjs from "dayjs";

type Props = {
  article: GatsbyTypes.ContentfulEvent;
  seminar?: Seminar;
};

const detailedFormEnabledId = ["o9uYkIt7kW6ux5UYIfP4f"];

export const EventArticleBody: React.FC<Props> = ({ article, seminar }) => {
  const detailedFormEnabled = detailedFormEnabledId.includes(article.contentful_id);
  const renderTimeTable = article.timeTable?.map((node, index) => {
    const content = JSON.parse(node?.internal?.content || ``);

    if (index > 1) {
      return (
        <Fragment key={`TimeTable_${index}`}>
          <style jsx>{`
            th {
              width: 20%;
              max-width: 200px;
              background-color: ${colors.creamGray};
              border: solid 1px ${colors.white};
              font-size: 15px;
              letter-spacing: 0.08rem;
            }
            td {
              padding: 30px 0px 30px 15px;
            }
            .programTitle {
              display: block;
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 8px;
            }
            .programSpeaker {
              display: block;
              font-size: 14px;
            }
            @media (${breakPoints.sp}) {
              tr {
                border-bottom: solid 10px ${colors.white};
                background-color: ${colors.creamGray};
              }
              th {
                border: none;
                padding: 0 15px;
              }
              td {
                padding: 30px 15px;
              }
              .programTitle {
                font-size: 15px;
              }
              .programSpeaker {
                font-size: 13px;
              }
            }
          `}</style>
          <tr>
            <th>{content[0]}</th>
            <td>
              <span className="programTitle">{content[1]}</span>
              <span className="programSpeaker">{content[2]}</span>
            </td>
          </tr>
        </Fragment>
      );
    } else {
      return null;
    }
  });

  const renderHoldingOverview = article?.holdingOverview
    ?.filter((_node, index) => index > 1)
    .map((node, index) => {
      const jsonContent = JSON.parse(node?.internal?.content || ``);

      return (
        <Fragment key={`HoldingOverview_${index}`}>
          <style jsx>{`
            th {
              width: 20%;
              max-width: 200px;
              background-color: ${colors.creamGray};
              border: solid 1px ${colors.white};
              font-size: 15px;
              font-weight: 500;
              padding: 15px;
            }
            td {
              padding: 30px 0px 30px 15px;
              font-size: 15px;
            }
            tr td :global(p) {
              margin: 0;
            }
            @media (${breakPoints.sp}) {
              tr {
                display: block;
                margin-bottom: 10px;
              }
              th {
                border: none;
                padding: 15px;
                display: block;
                width: 100%;
                max-width: none;
                text-align: left;
              }
              td {
                padding: 15px;
                width: 100%;
                display: block;
              }
              .programTitle {
                font-size: 15px;
              }
              .programSpeaker {
                font-size: 13px;
              }
            }
          `}</style>
          <tr>
            <th>{jsonContent && <span>{jsonContent[0]}</span>}</th>
            <td>{jsonContent && <span>{jsonContent[1]}</span>}</td>
          </tr>
        </Fragment>
      );
    });

  return (
    <>
      <style jsx>{`
        .mainContent {
          margin: 0 0 30px;
        }
        .eyeCatch {
          background-image: url(${article?.ogpImage?.fluid?.src});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          ${article?.ogpImage?.fluid?.aspectRatio && `padding-top: ${(1 / article.ogpImage.fluid?.aspectRatio) * 100}%;`}
          position: relative;
          width: 100%;
        }
        .mainContent :global(h2) {
          border-left: solid 3px ${colors.brand};
          color: ${colors.brand};
          font-size: 21px;
          margin: 40px;
          padding: 0 0 0 20px;
        }
        .mainContent :global(h3) {
          border-bottom: solid 1px ${colors.defaultText};
          font-size: 18px;
          padding: 0 0 10px;
          margin: 40px;
        }
        .mainContent :global(h4) {
          font-size: 18px;
          font-weight: 500;
          margin: 40px;
        }
        .mainContent :global(p) {
          font-size: 16px;
          margin: 25px 40px;
        }
        .mainContent :global(p:empty) {
          margin: 0;
        }
        .mainContent :global(.embeddedImageWrapper) {
          margin: 0 40px;
          width: calc(100% - 80px);
        }
        .mainContent :global(.embeddedImageWrapper img) {
          display: block;
          max-width: 100%;
        }
        .mainContent :global(.embeddedImageWrapper .description) {
          color: ${colors.littleBrightGray};
          font-size: 14px;
        }
        .mainContent :global(.dropShadowButtonWrapper) {
          display: flex;
          margin: 50px auto;
          justify-content: center
          text-align: center;
        }
        .mainContent :global(.dropShadowButtonWrapper a) {
          display: block;
          width: 100%;
        }
        .mainContent :global(.quote) {
          background-position: 15px 15px, calc(100% - 15px) calc(100% - 15px);
          background-repeat: no-repeat;
          font-style: italic;
          margin: 25px 40px;
          padding: 15px 30px;
        }
        .mainContent :global(.quote p) {
          margin: 25px 0;
        }
        .programTable {
          border: solid 1px ${colors.white};
          border-collapse: collapse;
          width: calc(100% - 80px);
          margin: 30px 40px;
        }
        .holdingOverview {
          border: solid 1px ${colors.white};
          border-collapse: collapse;
          width: calc(100% - 80px);
          margin: 30px 40px;
        }
        .form {
          background-color: ${colors.creamGray};
          border-radius: 3px;
          margin: 30px 40px;
        }
        .inputRow {
          border-bottom: solid 2px ${colors.white};
          padding: 20px 15px;
        }
        .half {
          display: inline-block;
          vertical-align: top;
          width: calc(50% - 15px);
        }
        .half:nth-child(2) {
          margin-left: 30px;
        }
        .submitWrapper {
          margin: 30px auto;
          text-align: center;
        }
        .submitWrapper :global(button) {
          font-size: 20px;
          height: 88px;
          min-width: 400px;
        }
        .submitWrapper :global(button svg) {
          height: 15px;
          width: 10px;
        }
        @media (${breakPoints.sp}) {
          .mainContent :global(h2) {
            font-size: 18px;
            margin: 30px 20px;
          }
          .mainContent :global(h3) {
            font-size: 17px;
            margin: 30px 20px
          }
          .mainContent :global(h4) {
            font-size: 16px;
            margin: 30px 20px
          }
          .mainContent :global(p) {
            font-size: 15px;
            margin: 25px 20px;
          }
          .mainContent :global(.embeddedImageWrapper) {
            margin: 0 20px;
            width: calc(100% - 40px);
          }
          .mainContent :global(.quote) {
            margin: 25px 20px;
          }
          .programTable {
            margin: 30px 20px;
            width: calc(100% - 40px);
          }
          .holdingOverview {
            margin: 30px 20px;
            width: calc(100% - 40px);
          }
        }
      `}</style>
      <div className="mainContent">
        <div className="eyeCatch" />
        {article.headContent && renderRichText(article.headContent, Options)}
        {article.eventOverview && (
          <>
            <h2>セミナー概要</h2>
            {renderRichText(article.eventOverview, Options)}
          </>
        )}
        {article.whoFor && (
          <>
            <h2>こんな企業様に最適</h2>
            {renderRichText(article.whoFor, Options)}
          </>
        )}
        {renderTimeTable && renderTimeTable.length > 2 && (
          <>
            <h2>プログラム</h2>
            <table className="programTable">
              <tbody>{renderTimeTable}</tbody>
            </table>
          </>
        )}
        {article.holdingOverview && article.holdingOverview.length > 2 && (
          <>
            <h2>開催概要</h2>
            <table className="holdingOverview">
              <tbody>{renderHoldingOverview}</tbody>
            </table>
          </>
        )}
        {article?.speaker && (
          <>
            <h2>登壇者</h2>
            {article.speaker.map((speaker, index) => (
              <Speaker
                key={`Speakers_${index}`}
                imageUrl={speaker?.facePhotograph?.fluid?.src || ``}
                name={speaker?.name || ``}
                company={speaker?.company || ``}
                position={speaker?.position || ``}
                description={speaker?.description?.description || ``}
              />
            ))}
          </>
        )}
        {article.precautions && (
          <>
            <h2>ご注意事項</h2>
            {renderRichText(article.precautions, Options)}
          </>
        )}
        {seminar?.available && (
          <>
            <h2>お申し込み</h2>
            {(detailedFormEnabled && (
              <SeminarDetailedForm campaignTitle={`${dayjs(article?.date).format("YYMMDD")}.${article?.title}`} />
            )) || <SeminarForm campaignTitle={`${dayjs(article?.date).format("YYMMDD")}.${article?.title}`} />}
          </>
        )}
      </div>
    </>
  );
};
