import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";

interface Props {
  title: string;
  url: string;
}

export const ShareToSNS: React.FC<Props> = ({ title, url }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const sharedUrl = `${site.siteMetadata.siteUrl}${encodeURI(url)}`;

  return (
    <>
      <style jsx>{`
        .sns {
          display: flex;
        }

        .sns :global(.facebookShareButton) {
          display: block;
          outline: none;
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }

        .sns :global(.twitterShareButton) {
          display: block;
          outline: none;
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      `}</style>
      <div className="sns">
        <FacebookShareButton className="facebookShareButton" url={sharedUrl}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton className="twitterShareButton" url={sharedUrl} title={title}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>
    </>
  );
};
