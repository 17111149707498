import React, { FormEvent, useCallback, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { navigate } from "gatsby";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VALIDATION_DEFAULT_MESSAGE, VALIDATION_DEFAULT_TEXT, VALIDATION_CHOICE } from "~/utils/validation";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { PRIVACY_POLICY_URL, PRIVACY_POLICY_MARKETING_URL } from "~/constant/path";
import rgba from "polished/lib/color/rgba";
import { SubmitButton } from "~/atoms/SubmitButton";
import { getGaClientId } from "~/utils/getGaClientId";

type FormValues = {
  lastName: string;
  firstName: string;
  companyName: string;
  email: string;
  phone: string;
  position: string;
  role: string;
  question: string;
  seminarSource: string;
  otherSeminarSource: string;
  permissionPrivacyPolicy: boolean;
};

const initialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  phone: "",
  position: "",
  role: "",
  question: "",
  seminarSource: "",
  otherSeminarSource: "",
  permissionPrivacyPolicy: false,
};

const SEMINAR_ENDPOINT = `https://formspree.io/f/xrgolown`;
const SEMINAR_THANKS_PAGE = `/events/seminar/thanks`;

const seminarSources = ["Refcomeからのメール配信", "Refcome社員からのご案内", "SNS", "Refcomeのホームページ", "その他"];


export const SeminarForm: React.FC<{ campaignTitle: string }> = ({ campaignTitle }) => {
  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
    firstName: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
    companyName: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
    email: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required).email(VALIDATION_DEFAULT_TEXT.email),
    phone: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
    position: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
    seminarSource: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
    otherSeminarSource: Yup.string().when(["seminarSource"], {
      is: (seminarSource: string) => seminarSource === "その他",
      then: (schema) => schema.required(VALIDATION_DEFAULT_MESSAGE.required),
    }),
    permissionPrivacyPolicy: Yup.boolean().oneOf([true], VALIDATION_CHOICE.accepted),
  });

  const formRef = useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<{
    status: boolean;
    message: string;
  } | null>(null);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleSubmit(values),
    validationSchema,
  });

  const handleServerResponse = (status, message) => {
    setSubmitting(false);
    setResult({
      status,
      message,
    });

    if (status) {
      formik.resetForm({ values: formik.values });
      formRef.current?.reset();
      navigate(SEMINAR_THANKS_PAGE);
    }
  };

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      setSubmitting(true);

      const { lastName, firstName, companyName, email, phone, position, role, question, seminarSource, otherSeminarSource } = values;

      const formData = new FormData();
      const gaCid = getGaClientId();

      formData.append(`lastName`, lastName);
      formData.append(`firstName`, firstName);
      formData.append(`companyName`, companyName);
      formData.append(`email`, email);
      formData.append(`phone`, phone);
      formData.append(`position`, position);
      formData.append(`role`, role);
      formData.append(`question`, question);
      formData.append(`seminarSource`, seminarSource);
      formData.append(`otherSeminarSource`, otherSeminarSource);
      formData.append(`gaClientId`, gaCid || "");
      // @Note: セミナー判別用に固有の情報を送ります
      formData.append(`campaignTitle`, campaignTitle);
      formData.append(`seminarUrl`, location.href);

      try {
        await axios({
          method: "post",
          url: SEMINAR_ENDPOINT,
          data: formData,
        });
        handleServerResponse(true, `success`);
        ga("set", "dimension2", gaCid);
      } catch (error) {
        handleServerResponse(false, error.response.data.error);
        throw new Error(error.message);
      }

      return null;
    },
    [submitting, result]
  );

  return (
    <>
      <style jsx>{`
        .form {
          background-color: ${colors.white};
          padding: 0 40px;
          margin: 30px 0;
        }
        .formGroup {
          margin-bottom: 20px;
        }
        .label {
          font-size: 13px;
        }
        .textInput {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          height: 38px;
          width: 100%;
          box-sizing: border-box;
          display: block;
          box-shadow: none;
        }
        .textInput::placeholder {
          color: ${colors.gray2};
        }
        .textInput:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .textArea {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          height: 110px;
          width: 100%;
          box-sizing: border-box;
          display: block;
          box-shadow: none;
        }
        .textArea::placeholder {
          color: ${colors.gray2};
        }
        .textArea:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .checkListContainer {
          margin: 0 0 20px;
          display: flex;
        }
        .checkList {
          position: relative;
        }
        .checkListItem {
          position: relative;
          line-height: 1;
          margin-bottom: 5px;
        }
        .checkListItem:last-child {
          margin-bottom: 0;
        }
        .checkListLabel {
          color: ${colors.black};
          font-weight: bold;
          font-size: 13px;
          margin: 0 0 10px 0;
        }
        .checkboxInput {
          box-sizing: border-box;
          padding: 0;
          position: absolute;
          top: 0;
          opacity: 0;
        }
        .checkboxLabel {
          display: inline-flex;
          cursor: pointer;
        }
        .checkboxLabelIcon {
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          display: block;
          border-radius: 4px;
          background-color: ${colors.gray3};
          border: 2px solid ${colors.gray3};
          width: 20px;
          height: 20px;
          margin-right: 10px;
          flex: 0 0 auto;
        }
        .checkboxLabelIcon:hover {
          border-color: ${colors.orange};
          background-color: ${colors.gray};
          transition: background-color 0.2s, border-color 0.2s;
        }
        .checkboxLabelIcon:before {
          content: "";
          display: block;
          position: absolute;
          border-right: 2px solid ${colors.orange};
          border-bottom: 2px solid ${colors.orange};
          border-radius: 1px;
          opacity: 0.2;
          top: 0;
          left: 4px;
          width: 8px;
          height: 12px;
          transform: rotate(45deg);
          transition: opacity 0.15s ease-out;
        }
        .checkboxLabelIcon.checkboxLabelIconChecked {
          border-color: ${colors.orange};
          background-color: ${colors.orange};
        }
        .checkboxLabelIcon.checkboxLabelIconChecked:before {
          border-right: 2px solid ${colors.white};
          border-bottom: 2px solid ${colors.white};
          opacity: 1;
        }
        .checkboxLabelText {
          color: ${colors.black};
          font-size: 15px;
          line-height: 20px;
        }
        .checkboxLabelTextLink {
          color: ${colors.brand};
        }
        .checkListItem {
          position: relative;
          line-height: 1;
          margin-bottom: 5px;
        }
        .checkListItem:last-child {
          margin-bottom: 0;
        }
        .checkListLabel {
          color: ${colors.black};
          font-weight: bold;
          font-size: 13px;
          margin: 0 0 10px 0;
        }
        .checkboxInput {
          box-sizing: border-box;
          padding: 0;
          position: absolute;
          top: 0;
          opacity: 0;
        }
        .checkboxLabel {
          display: inline-flex;
          cursor: pointer;
        }
        .checkboxLabelIcon {
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          display: block;
          border-radius: 4px;
          background-color: ${colors.gray3};
          border: 2px solid ${colors.gray3};
          width: 20px;
          height: 20px;
          margin-right: 10px;
          flex: 0 0 auto;
        }
        .checkboxLabelIcon:hover {
          border-color: ${colors.orange};
          background-color: ${colors.gray};
          transition: background-color 0.2s, border-color 0.2s;
        }
        .checkboxLabelIcon:before {
          content: "";
          display: block;
          position: absolute;
          border-right: 2px solid ${colors.orange};
          border-bottom: 2px solid ${colors.orange};
          border-radius: 1px;
          opacity: 0.2;
          top: 0;
          left: 4px;
          width: 8px;
          height: 12px;
          transform: rotate(45deg);
          transition: opacity 0.15s ease-out;
        }
        .checkboxLabelIcon.checkboxLabelIconChecked {
          border-color: ${colors.orange};
          background-color: ${colors.orange};
        }
        .checkboxLabelIcon.checkboxLabelIconChecked:before {
          border-right: 2px solid ${colors.white};
          border-bottom: 2px solid ${colors.white};
          opacity: 1;
        }
        .checkboxLabelText {
          color: ${colors.black};
          font-size: 15px;
          line-height: 20px;
        }
        .checkboxLabelTextLink {
          color: ${colors.brand};
        }
        .selectContainer {
          display: block;
          position: relative;
        }
        .selectContainer :global(svg) {
          position: absolute;
          top: 9px;
          right: 8px;
          pointer-events: "none";
        }
        .select {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          height: 38px;
          width: 100%;
          display: block;
          box-sizing: border-box;
          box-shadow: none;
          transition: all 0.15s ease-out;
        }
        .select:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .selectEmpty {
          color: ${colors.gray2};
        }
        .errorText {
          color: ${colors.red};
          display: block;
          font-size: 13px;
        }
        .action {
          display: flex;
          justify-content: center;
          margin: 30px 0;
        }
        .submitButton {
          background-position: calc(100% - 30px) center;
          background-size: 15px auto;
          background: linear-gradient(
            270deg,
            #ea3375 -73%,
            #ea3572 -69.4%,
            #eb4656 -31.72%,
            #ec5145 1.32%,
            #ec553f 25.96%,
            #ec8317 100%
          );
          border-radius: 50px;
          position: relative;
          display: block;
          text-align: center;
          padding: 15px 20px;
          box-shadow: 0 0 12px ${rgba(colors.black, 0.1)};
          color: ${colors.white};
          font-size: 15px;
          font-weight: bold;
          transition: all 0.15s ease-out;
          max-width: 500px;
          width: 100%;
          opacity: 1;
        }
        .submitButton:hover {
          opacity: 0.8;
        }
        .submitButton:disabled {
          background: none;
          background-color: ${colors.gray3};
          color: ${colors.gray2};
          cursor: default;
          box-shadow: none;
        }
        .submitButton:disabled:hover {
          opacity: 1;
        }
        @media (${breakPoints.sp}) {
          .form {
            padding: 0 20px;
          }
        }
        @media (${breakPoints.minPc}) {
          .form {
            padding: 0 40px;
          }
          .formGroupContainer {
            display: flex;
            flex-direction: row;
          }
          .formGroupContainer .formGroup {
            display: flex;
            flex-direction: column;
            width: calc(50% - 15px);
          }
          .formGroupContainer .formGroup + .formGroup {
            margin-left: 30px;
          }
        }
      `}</style>
      <form
        className="form"
        ref={formRef}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="formGroupContainer">
          <div className="formGroup">
            <label className="label">会社名</label>
            <input
              type="text"
              className="textInput"
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              placeholder="会社名"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("companyName", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <span className="errorText">{formik.errors.companyName}</span>
            )}
          </div>
        </div>
        <div className="formGroupContainer">
          <div className="formGroup">
            <label className="label">姓</label>
            <input
              type="text"
              className="textInput"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              placeholder="姓"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("lastName", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <span className="errorText">{formik.errors.lastName}</span>
            )}
          </div>
          <div className="formGroup">
            <label className="label">名</label>
            <input
              type="text"
              className="textInput"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              placeholder="名"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("firstName", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <span className="errorText">{formik.errors.firstName}</span>
            )}
          </div>
        </div>
        <div className="formGroupContainer">
          <div className="formGroup">
            <label className="label">メールアドレス</label>
            <input
              className="textInput"
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              placeholder="メールアドレス"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("email", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && <span className="errorText">{formik.errors.email}</span>}
          </div>
          <div className="formGroup">
            <label className="label">電話番号</label>
            <input
              className="textInput"
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              placeholder="電話番号"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("phone", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone && formik.errors.phone && <span className="errorText">{formik.errors.phone}</span>}
          </div>
        </div>
        <div className="formGroupContainer">
          <div className="formGroup">
            <label className="label">役職名</label>
            <input
              className="textInput"
              type="text"
              id="position"
              name="position"
              value={formik.values.position}
              placeholder="役職名"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("position", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.position && formik.errors.position && <span className="errorText">{formik.errors.position}</span>}
          </div>
          <div className="formGroup">
            <label className="label">貴社内での役割</label>
            <input
              className="textInput"
              type="text"
              id="role"
              name="role"
              value={formik.values.role}
              placeholder="人事部門統括、中途採用担当、リファラル推進担当"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("role", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.role && formik.errors.role && <span className="errorText">{formik.errors.role}</span>}
          </div>
        </div>
        <div className="formGroupContainer">
          <div className="formGroup">
            <label className="label">聞きたいこと</label>
            <textarea
              className="textArea"
              id="question"
              name="question"
              value={formik.values.question}
              placeholder="聞きたいこと"
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                e.preventDefault();
                formik.setFieldValue("question", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.question && formik.errors.question && <span className="errorText">{formik.errors.question}</span>}
          </div>
        </div>
        <div className="formGroupContainer">
          <div className="formGroup">
            <label className="label">本セミナーを知った理由</label>
            <div className="selectContainer">
              <select
                className={`select ${formik.values.seminarSource === "" && `selectEmpty`}`}
                id="seminarSource"
                name="seminarSource"
                value={formik.values.seminarSource}
                onChange={(e: FormEvent<HTMLSelectElement>) => {
                  e.preventDefault();
                  formik.setFieldValue("seminarSource", e.currentTarget.value, true);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="">選択...</option>
                {seminarSources.map((seminarSource) => (
                  <option key={seminarSource} value={seminarSource}>
                    {seminarSource}
                  </option>
                ))}
              </select>
              <ExpandMoreIcon style={{ fontSize: 22, color: colors.black }} />
            </div>
            {formik.touched.seminarSource && formik.errors.seminarSource && (
              <span className="errorText">{formik.errors.seminarSource}</span>
            )}
          </div>
          {formik.values.seminarSource === "その他" && (
            <div className="formGroup">
              <label className="label">その他の内容</label>
              <input
                className="textInput"
                type="text"
                id="otherSeminarSource"
                name="otherSeminarSource"
                value={formik.values.otherSeminarSource}
                placeholder="その他の内容"
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  formik.setFieldValue("otherSeminarSource", e.currentTarget.value, true);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.otherSeminarSource && formik.errors.otherSeminarSource && <span className="errorText">{formik.errors.otherSeminarSource}</span>}
            </div>
          )}
        </div>
        <div className="checkListContainer">
          <div className="checkList">
            <div className="checkListItem">
              <input
                className="checkboxInput"
                type="checkbox"
                id="permissionPrivacyPolicy"
                checked={formik.values.permissionPrivacyPolicy}
                onChange={() => {
                  const newValue = !formik.values.permissionPrivacyPolicy;
                  formik.setFieldValue("permissionPrivacyPolicy", newValue, true);
                }}
              />
              <label className="checkboxLabel" htmlFor="permissionPrivacyPolicy">
                <span
                  className={`checkboxLabelIcon ${formik.values.permissionPrivacyPolicy && `checkboxLabelIconChecked`}`}
                />
                <span className="checkboxLabelText">
                  <a className="checkboxLabelTextLink" href={PRIVACY_POLICY_URL} target="_blank">
                    プライバシーポリシー
                  </a>
                  及び
                  <a className="checkboxLabelTextLink" href={PRIVACY_POLICY_MARKETING_URL} target="_blank">
                    個人情報のお取り扱い
                  </a>
                  に同意する
                </span>
              </label>
            </div>
          </div>
          {formik.touched.permissionPrivacyPolicy && formik.errors.permissionPrivacyPolicy && (
            <span className="errorText">{formik.errors.permissionPrivacyPolicy}</span>
          )}
        </div>
        <SubmitButton disabled={!formik.dirty || !formik.isValid || submitting} />
      </form>
    </>
  );
};
