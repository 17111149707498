import React from "react";
import { graphql } from "gatsby";
import dayjs from "dayjs";
import { Layout } from "~/templates/Layout";
import { PATH } from "~/constant/path";
import { ArticleHeader } from "~/molecules/ArticleHeader";
import { EventArticleBody } from "~/molecules/EventArticleBody";
import { ArticleFooter } from "~/molecules/ArticleFooter";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { EventArticleCards } from "~/molecules/EventArticleCards";
import { Container } from "~/atoms/Container";
import { ArticleContent } from "~/organisms/ArticleContent";
import { Seminar, seminarStatus } from "~/constant/seminar";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  path: string;
  data: {
    article: GatsbyTypes.ContentfulEvent;
    otherEventArticles: GatsbyTypes.ContentfulEventConnection;
  };
}

const ArticleEvent: React.FC<Props> = ({ path, data }) => {
  const { article, otherEventArticles } = data;
  const breadCrumbItems = [
    PATH.TOP,
    PATH.EVENTS,
    PATH.EVENTS_SEMINAR,
    {
      name: article.title,
    },
  ];
  const seminar: Seminar = dayjs().isAfter(article.endedAt)
    ? seminarStatus.end
    : (article.receptionStatus && seminarStatus.open) || seminarStatus.close;

  const eventArticles = otherEventArticles.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/${edge.node.eventCategory?.slug}/${edge.node.contentful_id}`,
    };
  });

  const sharedOgpTitle = article?.ogpTitle || ``;
  const sharedOgpDescription = article?.ogpDescription?.ogpDescription || ``;

  return (
    <>
      <style jsx>{`
        .articleWrapper {
          background-color: ${colors.creamGray};
        }

        .articleWrapper :global(.container) {
          padding: 30px 0 50px;
        }

        @media (${breakPoints.sp}) {
          .articleWrapper :global(.container) {
            flex-direction: column;
            padding: 0 0 50px;
          }
        }

        @media (${breakPoints.pc}) {
          .articleWrapper :global(.article) {
            margin: 0 auto;
            width: calc(100% - 60px);
          }
        }
      `}</style>
      <Layout
        title={article.title || sharedOgpTitle}
        ogpDescription={sharedOgpDescription}
        ogpImageUrl={(article.ogpImage && `https:${article!.ogpImage!.fluid!.src}`) || undefined}
        ogpTitle={sharedOgpTitle}
        breadCrumbItems={breadCrumbItems}
        breadCrumbBgColor={colors.creamGray2}
      >
        <div className="articleWrapper">
          <Container>
            <ArticleContent>
              <ArticleHeader
                categoryName={PATH.EVENTS_SEMINAR.name}
                title={article.title || ``}
                eventDetail={{
                  startedAt: article.date,
                  endedAt: article.endedAt,
                  location: article.location || ``,
                }}
                seminar={seminar}
              />
              <EventArticleBody article={article} seminar={seminar} />
              <ArticleFooter url={path} title={article.title || ``} ogpTitle={sharedOgpTitle} />
            </ArticleContent>
          </Container>
        </div>
        <ArticleListSection title={PATH.EVENTS_SEMINAR.name}>
          <EventArticleCards articles={eventArticles} />
        </ArticleListSection>
      </Layout>
    </>
  );
};

export default ArticleEvent;

export const articleEventQuery = graphql`
  query ArticleEvent($contentfulId: String) {
    article: contentfulEvent(contentful_id: { eq: $contentfulId }) {
      timeTable {
        internal {
          content
        }
      }
      date
      ogpTitle
      ogpDescription {
        internal {
          content
        }
      }
      endedAt
      eventCategory {
        slug
        name
      }
      eventOverview {
        raw
      }
      headContent {
        raw
      }
      holdingOverview {
        internal {
          content
        }
      }
      contentful_id
      location
      ogpImage {
        fluid(maxWidth: 900, quality: 100) {
          src
          aspectRatio
        }
      }
      receptionStatus
      speaker {
        company
        description {
          description
        }
        facePhotograph {
          fluid(maxWidth: 300, quality: 100) {
            src
          }
        }
        name
        position
      }
      title
      whoFor {
        raw
      }
      precautions {
        raw
      }
    }
    otherEventArticles: allContentfulEvent(
      filter: { contentful_id: { ne: $contentfulId } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          endedAt
          eventCategory {
            slug
          }
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          location
          title
          contentful_id
          receptionStatus
        }
      }
    }
  }
`;
